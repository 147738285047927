import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Container from "../../components/container/container";
import Navigation from "../../components/navigation/navigation";
import Summaries from "../../components/blog/summary/summaries";
import SEO from "../../components/blog/seo/seo";
export const _frontmatter = {
  "lang": "en",
  "title": "Healthy Eats - Fast Weight Loss - Muscle Growth Blog",
  "summary": "Healthy eats, fast weight loss, muscle growth and much more. Learn everything about healthy food, muscle building diets and the best way to lose weight. Start now!",
  "keywords": "muscle grow, mass gain, to build muscle, bigger muscles, lean bulk, muscle growth female, skinny to muscular, bulk diet, to bulk up, muscle building diet, fast weight loss, healthy food, high protein foods, eating, protein foods, healthy food, cutting diet, best way to lose weight"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = function Layout({
  children
}) {
  return <>{children}</>;
};
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO lang={props.pageContext.frontmatter.lang} description={props.pageContext.frontmatter.summary} title={props.pageContext.frontmatter.title} keywords={props.pageContext.frontmatter.keywords} mdxType="SEO" />
    <Container mdxType="Container">
  <Navigation mdxType="Navigation" />
  <Summaries mdxType="Summaries" />
    </Container>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      